<template>
  <div class="app-calendar overflow-hidden border">
    <!--
    <div class="demo-spacing-0 mb-1">
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span
                ><strong>In Progress!</strong> Not Finish</span
              >
            </div>
          </b-alert>
        </div>
      -->
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="
          col
          app-calendar-sidebar
          flex-grow-0
          overflow-hidden
          d-flex
          flex-column
        "
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          :admin-filter.sync="adminFilter"
          :meetingtag-options="meetingtagOptions"
          :tag-filter.sync="tagFilter"
          :select-all.sync="selectAll"
          
        />
      </div>

      <!-- Calendar -->

      <div class="col position-relative">
        
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />

      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        :client-options="clientOptions"
        :project-options="projectOptions"
        :contract-options="contractOptions"
        :meetingstage-options="meetingstageOptions"
        :meetingtag-options="meetingtagOptions"
        :user-options="userOptions"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import { BAlert } from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useMeeting from "./useCalendar";
import meetingStoreModule from "./meetingStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";
export default {
  components: {
    FullCalendar,
    CalendarSidebar,
    CalendarEventHandler,
    BAlert,
  },
  setup() {
    const MEETING_STORE_MODULE_NAME = "meeting";

    // Register module
    if (!store.hasModule(MEETING_STORE_MODULE_NAME))
      store.registerModule(MEETING_STORE_MODULE_NAME, meetingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEETING_STORE_MODULE_NAME))
        store.unregisterModule(MEETING_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
     
      // ----- UI ----- //
      isEventHandlerSidebarActive,

      // Filter

      adminFilter,
      // Tags
      tagFilter,
      selectAll,
    } = useCalendar();

    fetchEvents();

    //Options for calendar event handler
    const projectOptions = ref([]);
    const contractOptions = ref([]);
    const clientOptions = ref([]);
    const meetingstageOptions = ref([]);
    const meetingtagOptions = ref([]);
    const userOptions = ref([]);

    store
      .dispatch("app/fetchOptionList", {
        user: true,
        client: true,
        project: true,
        contract: true,
        meeting_stage: true,
        meeting_tag: true,
      })
      .then((response) => {
        userOptions.value = response.data.users;
        projectOptions.value = [];
        contractOptions.value = [];
        clientOptions.value = response.data.clients;
        meetingstageOptions.value = response.data.meeting_stages;
        meetingtagOptions.value = response.data.meeting_tags;
        //console.log('meetingtagOptions', meetingtagOptions.value)
      })
      .catch((error) => {});

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      // ------Option ------//
      userOptions,
      projectOptions,
      contractOptions,
      clientOptions,
      meetingstageOptions,
      meetingtagOptions,
      adminFilter,
      // Tags
      tagFilter,
      selectAll,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

</style>

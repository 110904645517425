import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, data ) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/meeting/list', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/meeting', eventData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/meeting/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/meeting`, {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOptions(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/option/list', {params:data})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }
    
  },
}

<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">{{ eventLocal.id ? "Update" : "Add" }} Event</h5>
          <div>
            <!--
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-event');
                hide();
              "
            /> -->
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                confirmDelete();
              "
            /> 
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Booking Status"
              rules="required"
            >
              <b-form-group label="Booking Status" label-for="meeting_stage_id">
                <v-select
                  v-model="eventLocal.meeting_stage_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="meetingstageOptions"
                  label="stage"
                  :reduce="(option) => option.id"
                >
                  <template #option="{ stage, background_color }">
                    <div
                      class="rounded-circle d-inline-block mr-50 bg-primary"
                      :style="{
                        background: background_color + ' !important',
                        height: '10px',
                        width: '10px',
                      }"
                    />
                    <span> {{ stage }}</span>
                  </template>

                  <template #selected-option="{ stage, background_color }">
                    <div
                      class="rounded-circle d-inline-block mr-50 bg-primary"
                      :style="{
                        background: background_color + ' !important',
                        height: '10px',
                        width: '10px',
                      }"
                    />
                    <span> {{ stage }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Client -->
            <b-form-group label="Client" label-for="client_id">
              <v-select
                v-model="eventLocal.client_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientOptions"
                label="name_en"
                :reduce="(option) => option.id"
              >
                <!-- <template #option="{ company_name, client_code }">
                  <span class="d-inline-block align-middle">
                    [{{ client_code }}]{{ company_name }}</span
                  >
                </template> -->
              </v-select>
            </b-form-group>
            <!-- Event Title -->
            <validation-provider
              #default="validationContext"
              name="Event Title"
              rules="required"
            >
              <b-form-group label="Event Title" label-for="event-title">
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Event Title"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Event Content" label-for="event-description">
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.content"
              />
            </b-form-group>
            <!--  Address -->
            <validation-provider
              #default="validationContext"
              name=" Address"
              rules="required"
            >
              <b-form-group label=" Address" label-for="address">
                <b-form-textarea
                  id="event-description"
                  :state="getValidationState(validationContext)"
                  v-model="eventLocal.address"
                  placeholder="Event Address"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Assignee"
              rules="required"
            >
              <b-form-group label="Assignee" label-for="users">
                <v-select
                  multiple
                  v-model="eventLocal.users"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions"
                  label="name"
                  :reduce="(option) => option.id"
                >
                  <template #option="{ avatar, name }">
                    <b-avatar
                      size="26"
                      :src="avatar"
                      :text="avatarText(name)"
                    />

                    <span class="ml-50 d-inline-block align-middle">
                      {{ name }}</span
                    >
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules="required"
            >
              <b-form-group label="Start Date" label-for="start-date">
                <b-form-input
                  style="display: none"
                  v-model="eventLocal.start"
                  :state="getValidationState(validationContext)"
                  type="text"
                  class="mb-0"
                  placeholder="time_from"
                />
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  @on-close="changeStartTime(eventLocal.start)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="End Date"
              rules="required"
            >
              <b-form-group label="End Date" label-for="end-date">
                <b-form-input
                  style="display: none"
                  v-model="eventLocal.end"
                  :state="getValidationState(validationContext)"
                  type="text"
                  class="mb-0"
                  placeholder="time_from"
                />
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- All Day -->
            <b-form-group v-if="false">
              <b-form-checkbox
                v-model="eventLocal.allDay"
                name="check-button"
                switch
                inline
              >
                All Day
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Tag" label-for="meeting_tag_id">
              <v-select
                v-model="eventLocal.meeting_tag_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="meetingtagOptions"
                label="tag"
                :reduce="(option) => option.id"
              >
                <template #option="{ tag }">
                  <span class="d-inline-block align-middle">{{ tag }}</span>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group label=" Booking Remark" label-for="meeting_remark">
                <!--
                <b-form-textarea
                  id="event-description"
                  v-model="eventLocal.meeting_remark"
                  placeholder="Booking Remark"
                />-->
                <quill-editor
                  id="quil-content"
                  v-model="eventLocal.meeting_remark"
                  :options="editorOption"
                  class="border-bottom-0"
                />
                <div
                  id="quill-toolbar"
                  class="d-flex justify-content-end border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
              </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? "Update" : "Add " }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeTab()"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";

import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    flatPickr,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    quillEditor,

    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    projectOptions: {
      type: Array,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    meetingtagOptions: {
      type: Array,
      required: true,
    },
    meetingstageOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getContracts() {
      return this.contractOptions.filter(
        (item) => this.eventLocal.client_id == item.client_id
      );
    },
    getProjects() {
      return this.projectOptions.filter(
        (item) => this.eventLocal.client_id == item.client_id
      );
    },
    changeStartTime(item) {
      this.eventLocal.end = item;
      console.log("change start time", item, this.eventLocal.end);
    },
    confirmDelete()
    {
        this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log('remove-event');
          this.$emit('remove-event');
          this.closeTab()
        }
      })
    },
    deleteMeeting() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });
    },
  },
  data() {
    return {
      required,
      email,
      url,
    };
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null);

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
      closeTab,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEventLocal, props.clearEventData);

    clearFormData.value = clearForm;

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Booking Remark",
    };

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,
      editorOption,
      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      avatarText,
      closeTab,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss">
.ql-editor {
  min-height: 150px;
}
</style>



<template>
  <div
    class="
      sidebar-wrapper
      d-flex
      justify-content-between
      flex-column flex-grow-1
    "
  >
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        Add Event 
      </b-button>
      <!--
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Tags</span>
        </h5>
        <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tagFilter"
            :options="meetingtagOptions"
            label="tag"
            class="w-100"
            :reduce="val => val.id"
            @input="(val) => $emit('update:tagFilter', val)"
          />
      </div>
      -->
      <!--
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Tags</span>
        </h5>
        
        <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
        >
          View All
        </b-form-checkbox>
        

        <b-form-group>
          <b-form-checkbox-group 
          name="event-filter" 
          v-model="selectedTags"
          @change="tagChanged()"
          stacked>
            <b-form-checkbox
              v-for="item in meetingtagOptions"
              :key="item.id"
              name="event-filter"
              :value="item.id"
              class="mb-1"
              
            >
              {{ item.tag }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      -->
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useCalendarSidebar from "./useCalendarSidebar";
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    vSelect,
  },
  mounted() {
    if (this.$router.currentRoute.name == "admin-meetings") {
      this.$emit("update:adminFilter", true);
    }
  },
  data() {
    return {
      selectedTags: [],
    };
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    adminFilter: {
      type: [Boolean, null],
      default: false,
    },
    meetingtagOptions: {
      type: Array,
      require: true,
    },
    tagFilter: {
      type: Array,
      require: true,
    },
    selectAll: {
      type: [Boolean, true],
      default: true,
    },
  },
  methods: {
    tagChanged() {
      console.log("tagChanged");
      this.$emit("update:tagFilter", this.selectedTags);
    },
  },
  watch: {
    $route(to, from) {
      console.log("route", to, from);
      console.log(to.name);
      if (to.name == "admin-meetings") {
        this.$emit("update:adminFilter", true);
      } else if (to.name == "meetings") {
        this.$emit("update:adminFilter", false);
      }
    },
  },
  setup() {
    const { calendarOptions, selectedCalendars, checkAll } =
      useCalendarSidebar();

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>